:root {
  /* font-size: 16px; */
  font-size: 14px;

  /* ATOM VIZ VARIABLES */

  /* font */
  /* --atom-viz-font: 'Rubik'; */

  /* CIBC font */
  /* --atom-viz-font: 'Whitney Book'; */
  --atom-viz-font: 'Open Sans';

  --atom-viz-color-brand: #c41f3f;
  --atom-viz-color-brand-2: #8b1d41;
  --atom-viz-color-brand-3: #383b3e;

  /* background colors */
  --atom-viz-background-primary: #ffffff;
  --atom-viz-background-secondary: #eee;
  --atom-viz-background-tertiary: #c2c2c2;

  /* text colors */
  --atom-viz-text-primary: #3d3d3d;
  --atom-viz-text-secondary: #99999f;
  --atom-viz-text-tertiary: #99999f;
  --atom-viz-text-contrast: #fff;
  --atom-viz-text-link: blue;

  /* highlights and borders */
  --atom-viz-border: #eee;

  /* visualization colors */
  --atom-viz-color-1: #5194b9;
  --atom-viz-color-2: #864e96;
  --atom-viz-color-3: #b65f57;
  --atom-viz-color-4: #efb068;
  --atom-viz-color-5: #00974b;
  --atom-viz-price-up: #459c8d;
  --atom-viz-price-down: #b65f57;
  --atom-viz-good: #459c8d;
  --atom-viz-average: #efb068;
  --atom-viz-poor: #b65f57;

  /* Dark Mode Option */
  /* --atom-viz-font: 'Roboto Mono';

  --atom-viz-background-primary: #272736;
  --atom-viz-background-secondary: #464653;
  --atom-viz-background-tertiary: #787878;

  --atom-viz-text-primary: #fff;
  --atom-viz-text-secondary: #d1d1d1;
  --atom-viz-text-tertiary: #fff; */
}

/* Fonts */

@font-face {
  font-family: 'Whitney Book';
  src: url('./styles/fonts/Whitney-Book.ttf') format('truetype'),
    url('./styles/fonts/Whitney-Book-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney Book';
  src: url('./styles/fonts/Whitney-Bold.ttf') format('truetype'),
    url('./styles/fonts/Whitney-Bold-Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'D-Din Exp';
  src: url('./styles/fonts/D-DIN.eot');
  src: url('./styles/fonts/D-DIN.eot?#iefix') format('embedded-opentype'),
    url('./styles/fonts/D-DIN.woff2') format('woff2'),
    url('./styles/fonts/D-DIN.woff') format('woff'),
    url('./styles/fonts/D-DIN.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'D-Din Exp';
  src: url('./styles/fonts/D-DIN-Bold.eot');
  src: url('./styles/fonts/D-DIN-Bold.eot?#iefix') format('embedded-opentype'),
    url('./styles/fonts/D-DIN-Bold.woff2') format('woff2'),
    url('./styles/fonts/D-DIN-Bold.woff') format('woff'),
    url('./styles/fonts/D-DIN-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('./styles/fonts/GT-America-Standard-Regular.eot');
  src: url('./styles/fonts/GT-America-Standard-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./styles/fonts/GT-America-Standard-Regular.woff2') format('woff2'),
    url('./styles/fonts/GT-America-Standard-Regular.woff') format('woff'),
    url('./styles/fonts/GT-America-Standard-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('./styles/fonts/GT-America-Standard-Bold.eot');
  src: url('./styles/fonts/GT-America-Standard-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./styles/fonts/GT-America-Standard-Bold.woff2') format('woff2'),
    url('./styles/fonts/GT-America-Standard-Bold.woff') format('woff'),
    url('./styles/fonts/GT-America-Standard-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('./styles/fonts/GT-America-Standard-Light.eot');
  src: url('./styles/fonts/GT-America-Standard-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./styles/fonts/GT-America-Standard-Light.woff2') format('woff2'),
    url('./styles/fonts/GT-America-Standard-Light.woff') format('woff'),
    url('./styles/fonts/GT-America-Standard-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('./styles/fonts/Sora-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Wide Medium';
  src: url('./styles/fonts/Druk-Wide-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./styles/fonts/SFProText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sora';
  src: url('./styles/fonts/Sora-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./styles/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./styles/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* End Fonts */

html,
body,
#root {
  height: 100%;
  user-select: none;
  padding: 0px 0px;
}

body {
  background-color: var(--atom-viz-background-primary);
  color: var(--atom-viz-text-primary);
  margin: 0;
  font-family: 'SF Pro Text', 'D-Din Exp', 'GT America', 'system-ui',
    'BlinkMacSystemFont', '-apple-system', 'Segoe UI', 'Helvetica Neue',
    'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus,
button:focus {
  outline: 0;
}

a {
  text-decoration: none;
}

h2 {
  margin: 0;
}

a.anchor-hidden {
  height: 1px;
  visibility: hidden;
  overflow: hidden;
}

/* Animations for real-time price update fades */
@keyframes redFade {
  from {
    color: var(--text-primary);
    background-color: var(--price-down);
    border-radius: 25%;
    font-weight: bold;
  }
  to {
    color: inherit;
    background: transparent;
    font-weight: bold;
  }
}

@keyframes greenFade {
  from {
    color: var(--text-primary);
    background-color: var(--price-up);
    border-radius: 25%;
    font-weight: bold;
  }
  to {
    color: inherit;
    background: transparent;
    font-weight: bold;
  }
}

.redFade {
  animation-name: redFade;
  animation-duration: 1s;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s linear;
}
.atomHidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
}

.greenFade {
  animation-name: greenFade;
  animation-duration: 1s;
}

.ReactModalPortal {
  overflow-y: scroll;
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--after-open .ReactModal__Content {
  opacity: 1;
}

/* Fade out modal */
.ReactModal__Overlay--before-close {
  opacity: 0;
}

.HoverGreen:hover {
  color: var(--green);
}

.transition-smooth {
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.dropdown-options.atom-show {
  overflow-y: hidden;
}

.dropdown-options {
  -webkit-transiton: height ease-out 0.2s;
  -moz-transition: height ease-out 0.2s;
  -ms-transition: height ease-out 0.2s;
  -o-transition: height ease-out 0.2s;
  transition: height ease-out 0.2s;

  height: 0px;
  margin: 0 auto 0 auto;
  overflow: hidden;
}

.trackingPopup {
  position: absolute;
  top: 50px;
  font-size: 26px;
  background-color: var(--atom-brand-2);
  border-radius: 8px;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  width: 90%;
  z-index: 10000000;
  text-align: center;
  left: 0;
  right: 0;
}

.atomFadeOut {
  animation: fadeout ease-in-out 5s 1;
  -webkit-animation: fadeout ease-in-out 5s 1;
  opacity: 0;
}

.atomFadeOutAbruptly {
  animation: fadeout cubic-bezier(0.5, 0, 1, 1) 1s 1;
  -webkit-animation: fadeout cubic-bezier(0.5, 0, 1, 1) 1s 1;
  opacity: 0;
}

.react-tagsinput-input {
  width: 500px;
}

.react-datepicker__input-container input {
  background-color: var(--atom-brand-5);
  border-radius: 10px;
  width: 130px;
  color: var(--contrast);
  cursor: pointer;
  padding: 4px 4px 4px 16px;
}

.react-datepicker__input-container::after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f0d7';
  position: absolute;
  left: 110px;
  top: 2px;
  color: var(--contrast);
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.no-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.atom-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.atom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

[data-rsbs-overlay] {
  border-top-left-radius: var(--rsbs-overlay-rounded);
  border-top-right-radius: var(--rsbs-overlay-rounded);
  display: flex;
  background: var(--rsbs-bg);
  flex-direction: column;
  height: var(--rsbs-overlay-h);
  transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
  will-change: height;
}
[data-rsbs-overlay]:focus {
  outline: none;
}
[data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
  box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
    0 -1px 0 rgba(38, 89, 115, 0.05);
}
[data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: var(--rsbs-max-w);
  margin-left: var(--rsbs-ml);
  margin-right: var(--rsbs-mr);
}
[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 3;
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
[data-rsbs-backdrop] {
  top: -60px;
  bottom: -60px;
  background-color: var(--rsbs-backdrop-bg);
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
}
[data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: '';
  pointer-events: none;
  background: var(--rsbs-bg);
  height: 1px;
  transform-origin: bottom;
  transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
  will-change: transform;
}
[data-rsbs-footer],
[data-rsbs-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 16px;
}
[data-rsbs-header] {
  text-align: center;
  user-select: none;
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125));
  z-index: 1;
  padding-top: calc(20px + env(safe-area-inset-top));
  padding-bottom: 8px;
}
[data-rsbs-header]:before {
  position: absolute;
  content: '';
  display: block;
  width: 36px;
  height: 4px;
  top: calc(8px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: var(--rsbs-handle-bg);
}
@media (min-resolution: 2dppx) {
  [data-rsbs-header]:before {
    transform: translateX(-50%) scaleY(0.75);
  }
}
[data-rsbs-has-header='false'] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(12px + env(safe-area-inset-top));
}
[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}
[data-rsbs-scroll]:focus {
  outline: none;
}
[data-rsbs-has-footer='false'] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}
[data-rsbs-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
  overflow: hidden;
}
[data-rsbs-footer] {
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125)),
    0 2px 0 var(--rsbs-bg);
  overflow: hidden;
  z-index: 1;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

[data-rsbs-is-dismissable='true'],
[data-rsbs-is-dismissable='false']:matches([data-rsbs-state='opening'], [data-rsbs-state='closing']) {
  & :matches([data-rsbs-header], [data-rsbs-scroll], [data-rsbs-footer]) > * {
    opacity: var(--rsbs-content-opacity);
  }
  & [data-rsbs-backdrop] {
    opacity: var(--rsbs-backdrop-opacity);
  }
}

[data-rsbs-state='closed'],
[data-rsbs-state='closing'] {
  /* Allows interactions on the rest of the page before the close transition is finished */
  pointer-events: none;
}
