.carousel .control-dots .dot {
  background-color: none;
  opacity: 1;
  border-radius: 0;
  box-shadow: none;
  width: 0.6rem;
  height: 0.6rem;
}

.carousel .control-dots .dot.selected {
  background-color: var(--green);
}
